var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Checkbox",
    class: [{
      'Checkbox--hasError': _vm.hasError,
      'Checkbox--disabled': _vm.isDisabled
    }, "Checkbox--".concat(_vm.size)]
  }, [_c('input', {
    staticClass: "Checkbox-input",
    attrs: {
      "type": "checkbox",
      "id": _vm.computedId,
      "name": _vm.name,
      "disabled": _vm.isDisabled
    },
    domProps: {
      "checked": _vm.value
    },
    on: {
      "change": _vm.handleChange
    }
  }), _c('label', {
    staticClass: "Checkbox-label",
    attrs: {
      "for": _vm.computedId
    }
  }, [_c('div', {
    staticClass: "Checkbox-indicator"
  }, [_vm.value ? _c('i', {
    staticClass: "ri-check-line"
  }) : _vm._e()]), _vm.$slots.default ? _c('div', {
    staticClass: "Checkbox-content"
  }, [_vm._t("default")], 2) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }