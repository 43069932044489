var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isPlanBasic ? _c('div', {
    staticClass: "ToolbarActions--Group"
  }, [_vm.showShiftsButton && (_vm.selectedCar || _vm.selectedEmployee) ? _c('Button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: 'In Schichten Anzeigen',
      expression: "'In Schichten Anzeigen'"
    }],
    attrs: {
      "variant": "icon"
    },
    on: {
      "onClick": function onClick($event) {
        return _vm.handleShowOnPage('shifts');
      }
    }
  }, [_c('i', {
    staticClass: "ri-repeat-line"
  })]) : _vm._e(), (_vm.selectedEmployee || _vm.selectedCar) && _vm.showTripsButton ? _c('Button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: 'In Einzelfahrten Anzeigen',
      expression: "'In Einzelfahrten Anzeigen'"
    }],
    attrs: {
      "variant": "icon"
    },
    on: {
      "onClick": function onClick($event) {
        return _vm.handleShowOnPage('trips');
      }
    }
  }, [_c('i', {
    staticClass: "ri-steering-2-line"
  })]) : _vm._e(), _vm.selectedEmployee && _vm.showWorkingTimeButton ? _c('Button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: 'In Arbeitszeiten Anzeigen',
      expression: "'In Arbeitszeiten Anzeigen'"
    }],
    attrs: {
      "variant": "icon"
    },
    on: {
      "onClick": function onClick($event) {
        return _vm.handleShowOnPage('working-time');
      }
    }
  }, [_c('i', {
    staticClass: "ri-time-line"
  })]) : _vm._e(), _vm.selectedCar && _vm.showOperationRideButton ? _c('Button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: 'In Betriebsnachweise Anzeigen',
      expression: "'In Betriebsnachweise Anzeigen'"
    }],
    attrs: {
      "variant": "icon"
    },
    on: {
      "onClick": function onClick($event) {
        return _vm.handleShowOnPage('operationride');
      }
    }
  }, [_c('i', {
    staticClass: "ri-car-line"
  })]) : _vm._e()], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }