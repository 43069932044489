var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Card', {
    staticClass: "BaseDashboardTable"
  }, [_c('div', {
    staticClass: "BaseDashboardTable-header"
  }, [_c('div', {
    staticClass: "BaseDashboardTable-title"
  }, [_vm._t("title"), _vm.filteredItems.length > 0 && _vm.showItemCounter ? _c('span', {
    staticClass: "item-counter"
  }, [_vm._v(_vm._s(_vm.filteredItems.length) + " " + _vm._s(_vm.filteredItems.length === 1 ? 'Eintrag' : 'Einträge'))]) : _vm._e()], 2), _c('div', {
    staticClass: "BaseDashboardTable-actions"
  }, [_vm.hasSearch ? _c('div', {
    staticClass: "search-container"
  }, [_c('i', {
    staticClass: "ri-search-line search-icon"
  }), _c('input', {
    staticClass: "search-input",
    attrs: {
      "type": "text",
      "placeholder": _vm.searchPlaceholder
    },
    on: {
      "input": function input($event) {
        return _vm.debouncedSearch($event.target.value);
      }
    }
  })]) : _vm._e(), _vm._t("actions")], 2)]), _c('div', {
    ref: "tableContainer",
    staticClass: "BaseDashboardTable-container",
    style: {
      height: _vm.containerHeight
    }
  }, [_c('div', {
    ref: "scrollContainer",
    staticClass: "table-scroll-container"
  }, [_c('table', {
    style: {
      minWidth: _vm.tableMinWidth
    }
  }, [_c('thead', [_c('tr', [_vm.showCounter ? _c('th', {
    staticClass: "counter-column text-center",
    staticStyle: {
      "width": "50px",
      "min-width": "50px"
    }
  }, [_vm._v(" # ")]) : _vm._e(), _vm._l(_vm.columns, function (column, index) {
    return _c('th', {
      key: index,
      class: [column.align ? "text-".concat(column.align) : '', {
        sortable: column.sortable !== false
      }, {
        sorted: _vm.currentSort.column === column.key
      }, {
        'sorted-asc': _vm.currentSort.column === column.key && _vm.currentSort.ascending
      }, {
        'sorted-desc': _vm.currentSort.column === column.key && !_vm.currentSort.ascending
      }],
      style: _vm.getColumnStyle(column),
      on: {
        "click": function click($event) {
          return _vm.handleHeaderClick(column);
        }
      }
    }, [_c('div', {
      staticClass: "column-header"
    }, [_vm._v(" " + _vm._s(_vm.truncateText(column.label, _vm.headerTruncateLength)) + " "), column.sortable !== false ? _c('i', {
      class: [_vm.currentSort && _vm.currentSort.column === column.key ? _vm.currentSort.ascending ? 'ri-arrow-up-s-line active' : 'ri-arrow-down-s-line active' : 'ri-arrow-up-down-line']
    }) : _vm._e()])]);
  }), _vm.selectable ? _c('th', {
    staticClass: "checkbox-column"
  }, [_vm._v(" " + _vm._s(_vm.selectColumnLabel) + " "), _c('input', {
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": _vm.allSelected
    },
    on: {
      "change": _vm.toggleSelectAll
    }
  })]) : _vm._e()], 2)]), _c('tbody', [_vm.startIndex > 0 ? _c('tr', {
    staticClass: "top-spacer",
    style: {
      height: _vm.topSpacerHeight + 'px'
    }
  }) : _vm._e(), _vm._l(_vm.visibleItems, function (item, index) {
    return [_c('BaseDashboardTableRow', {
      key: "".concat(item._id || index, "-").concat(_vm.startIndex + index),
      attrs: {
        "item": item,
        "index": _vm.startIndex + index + 1,
        "show-counter": _vm.showCounter,
        "columns": _vm.columns,
        "is-clickable": _vm.isClickable,
        "selectable": _vm.selectable,
        "has-any-expandable-rows": _vm.hasAnyExpandableRows,
        "is-checked": _vm.isChecked(item),
        "expanded": _vm.isRowExpanded(item),
        "is-selected": _vm.isSelected(item),
        "cell-truncate-length": _vm.cellTruncateLength,
        "global-components": _vm.globalComponents
      },
      on: {
        "row-click": function rowClick($event) {
          return _vm.handleRowClick($event.item, _vm.startIndex + $event.index, $event.event);
        },
        "row-hover": function rowHover($event) {
          return _vm.handleRowHover($event.item, _vm.startIndex + $event.index);
        },
        "row-leave": function rowLeave($event) {
          return _vm.handleRowLeave($event.item, _vm.startIndex + $event.index);
        },
        "toggle-expand": function toggleExpand($event) {
          return _vm.toggleExpand($event.item, _vm.startIndex + $event.index);
        },
        "item-checked": _vm.toggleItemChecked
      },
      scopedSlots: _vm._u([_vm._l(_vm.columns, function (column) {
        return {
          key: "column-".concat(column.key),
          fn: function fn(slotProps) {
            return [_vm._t("column-".concat(column.key), null, null, slotProps)];
          }
        };
      })], null, true)
    }), !_vm.useHierarchicalRows && _vm.isRowExpanded(item) ? _c('tr', {
      key: "".concat(_vm.startIndex + index, "-expanded"),
      staticClass: "nested-table-row"
    }, [_c('td', {
      attrs: {
        "colspan": _vm.columns.length
      }
    }, [_c('div', {
      staticClass: "nested-table-container"
    }, [_vm._t("nested-table", null, {
      "item": item,
      "index": _vm.startIndex + index
    })], 2)])]) : _vm._e()];
  }), _vm.endIndex < _vm.filteredItems.length ? _c('tr', {
    staticClass: "bottom-spacer",
    style: {
      height: _vm.bottomSpacerHeight + 'px'
    }
  }) : _vm._e()], 2)])])]), !_vm.filteredItems.length ? _c('div', {
    staticClass: "table-empty"
  }, [_c('i', {
    staticClass: "ri-inbox-line"
  }), _c('p', [_vm._v(_vm._s(_vm.searchQuery ? 'Keine Ergebnisse gefunden.' : _vm.emptyMessage))])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }