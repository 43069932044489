<template>
    <BaseDashboardTable
        :columns="columns"
        :items="sortedWorkingTimes"
        :sort="sortState"
        :empty-message="'Keine Arbeitszeiten gefunden'"
        @sort-changed="handleSortChanged"
        @row-click="handleRowClick"
        @row-hover="handleRowHover"
        @row-leave="handleRowLeave"
    >
        <template #title>
            <h3>Arbeitszeiten</h3>
        </template>

        <!-- Optional badge for rows with pauses -->
        <template #row-badge="{ item }">
            <span v-if="hasPauses(item)" class="pause-badge">P</span>
        </template>
    </BaseDashboardTable>
</template>

<script>
import { format } from 'date-fns';
import { getDuration } from '../../../../lib/helper';
import BaseDashboardTable from '@/components/BaseDashboardTable';

export default {
    name: 'WorkingTimesTable',
    components: {
        BaseDashboardTable,
    },
    props: {
        workingTimes: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            activeRow: -1,
            sortState: {
                column: 'date',
                ascending: true,
            },
            columns: [
                { key: 'date', label: 'Datum' },
                { key: 'shiftTime', label: 'Schichtzeit', align: 'center' },
                { key: 'workTime', label: 'Arbeitszeit', align: 'center' },
                { key: 'pauseTime', label: 'Pause', align: 'center' },
                { key: 'eightToSix', label: 'Std 20-6 uhr', align: 'center' },
                { key: 'zeroToFour', label: 'Std 0-4 uhr', align: 'center' },
                { key: 'sunday', label: 'Sonntag', align: 'center' },
                { key: 'holiday125', label: 'Feiertag 125%', align: 'center' },
                { key: 'holiday150', label: 'Feiertag 150%', align: 'center' },
            ],
        };
    },
    computed: {
        formattedWorkingTimes() {
            return this.workingTimes.map(workingTime => ({
                date: format(new Date(workingTime?.date), 'dd.MM.yy'),
                shiftTime: workingTime.shiftTime ? getDuration(workingTime.shiftTime) : '--:--',
                workTime: workingTime.workTime ? getDuration(workingTime.workTime) : '--:--',
                pauseTime: workingTime.pauseTime ? getDuration(workingTime.pauseTime) : '--:--',
                eightToSix: workingTime.eightToSix ? getDuration(workingTime.eightToSix) : '--:--',
                zeroToFour: workingTime.zeroToFour ? getDuration(workingTime.zeroToFour) : '--:--',
                sunday: workingTime.sunday ? getDuration(workingTime.sunday) : '--:--',
                holiday125: workingTime.holiday125 ? getDuration(workingTime.holiday125) : '--:--',
                holiday150: workingTime.holiday150 ? getDuration(workingTime.holiday150) : '--:--',

                // Raw values for sorting
                _date: new Date(workingTime?.date),
                _shiftTime: workingTime.shiftTime || 0,
                _workTime: workingTime.workTime || 0,
                _pauseTime: workingTime.pauseTime || 0,
                _eightToSix: workingTime.eightToSix || 0,
                _zeroToFour: workingTime.zeroToFour || 0,
                _sunday: workingTime.sunday || 0,
                _holiday125: workingTime.holiday125 || 0,
                _holiday150: workingTime.holiday150 || 0,

                // Original data reference
                originalData: workingTime,
                // Pause array for badge
                pause: workingTime.pause,
            }));
        },
        sortedWorkingTimes() {
            if (!this.formattedWorkingTimes.length) return [];

            const { column, ascending } = this.sortState;

            return [...this.formattedWorkingTimes].sort((a, b) => {
                // Determine which property to sort by
                let aValue, bValue;

                // Use raw numeric values for time fields and date
                if (column === 'date') {
                    aValue = a._date;
                    bValue = b._date;
                } else if (column === 'shiftTime') {
                    aValue = a._shiftTime;
                    bValue = b._shiftTime;
                } else if (column === 'workTime') {
                    aValue = a._workTime;
                    bValue = b._workTime;
                } else if (column === 'pauseTime') {
                    aValue = a._pauseTime;
                    bValue = b._pauseTime;
                } else if (column === 'eightToSix') {
                    aValue = a._eightToSix;
                    bValue = b._eightToSix;
                } else if (column === 'zeroToFour') {
                    aValue = a._zeroToFour;
                    bValue = b._zeroToFour;
                } else if (column === 'sunday') {
                    aValue = a._sunday;
                    bValue = b._sunday;
                } else if (column === 'holiday125') {
                    aValue = a._holiday125;
                    bValue = b._holiday125;
                } else if (column === 'holiday150') {
                    aValue = a._holiday150;
                    bValue = b._holiday150;
                } else {
                    // For text fields, use the displayed value
                    aValue = a[column];
                    bValue = b[column];

                    // Case insensitive string comparison
                    if (typeof aValue === 'string' && typeof bValue === 'string') {
                        aValue = aValue.toLowerCase();
                        bValue = bValue.toLowerCase();
                    }
                }

                // Handle undefined or null values
                if (aValue === undefined || aValue === null) aValue = '';
                if (bValue === undefined || bValue === null) bValue = '';

                // Perform the comparison
                if (aValue < bValue) return ascending ? -1 : 1;
                if (aValue > bValue) return ascending ? 1 : -1;
                return 0;
            });
        },
    },
    methods: {
        handleSortChanged(sort) {
            this.sortState = {
                column: sort.column,
                ascending: sort.ascending,
            };
        },
        handleRowClick({ item }) {
            this.$emit('onClick', item.originalData);
        },
        handleRowHover({ index }) {
            this.activeRow = index;
        },
        handleRowLeave() {
            this.activeRow = -1;
        },
        hasPauses(item) {
            return item.pause && item.pause.length > 0;
        },
    },
};
</script>

<style lang="scss" scoped>
.pause-badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background-color: var(--color-blue);
    color: white;
    border-radius: 50%;
    font-size: 12px;
    font-weight: bold;
    margin-left: 6px;
}
</style>
