var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', {
    class: {
      selected: _vm.isSelected,
      clickable: _vm.isClickable,
      checked: _vm.selectable && _vm.isChecked,
      expanded: _vm.expanded,
      'parent-row': _vm.hasChildren,
      'child-row': _vm.item._isChild,
      'gap-row': _vm.item._isGap
    },
    style: {
      '--indent-level': _vm.item._level || 0
    },
    attrs: {
      "id": _vm.item._id
    },
    on: {
      "click": function click($event) {
        return _vm.handleRowClick($event);
      },
      "mouseenter": _vm.handleRowHover,
      "mouseleave": _vm.handleRowLeave
    }
  }, [_vm.showCounter ? _c('td', {
    staticClass: "counter-column text-center",
    class: {
      indented: _vm.item._isChild
    }
  }, [_vm._v(" " + _vm._s(_vm.index) + " ")]) : _vm._e(), _vm._l(_vm.columns, function (column, colIndex) {
    return _c('td', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: _vm.getColumnTooltip(column),
        expression: "getColumnTooltip(column)"
      }],
      key: colIndex,
      class: [column.align ? "text-".concat(column.align) : '', {
        'first-column': colIndex === 0
      }, {
        indented: colIndex === 0 && _vm.item._isChild
      }]
    }, [colIndex === 0 && !_vm.item._isGap ? [_vm.hasChildren || _vm.hasAnyExpandableRows ? _c('span', {
      class: [_vm.hasChildren ? 'expand-toggle' : 'expand-toggle-spacer', {
        'is-expanded': _vm.expanded
      }],
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          _vm.hasChildren && _vm.toggleExpand();
        }
      }
    }, [_vm.hasChildren ? _c('i', {
      class: [_vm.expanded ? 'ri-arrow-down-s-line' : 'ri-arrow-right-s-line']
    }) : _vm._e()]) : _vm._e()] : _vm._e(), column.component ? [_c(_vm.resolveComponent(column.component), _vm._b({
      tag: "component",
      on: {
        "input": function input($event) {
          return _vm.handleComponentInput($event, column);
        }
      }
    }, 'component', _vm.getComponentProps(column), false))] : [_vm._t("column-".concat(column.key), function () {
      return [_c('span', [_vm._v(_vm._s(_vm.truncateText(_vm.getItemValue(column), _vm.cellTruncateLength)))])];
    }, {
      "item": _vm.item,
      "value": _vm.getItemValue(column),
      "column": column
    })]], 2);
  }), _vm.selectable ? _c('td', {
    staticClass: "checkbox-column"
  }, [_c('label', {
    staticClass: "checkbox-wrapper"
  }, [_c('input', {
    staticClass: "checkbox-input",
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": _vm.isChecked
    },
    on: {
      "change": _vm.toggleItemChecked,
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }), _vm.isChecked ? _c('span', {
    staticClass: "checkmark"
  }, [_c('i', {
    staticClass: "ri-check-line"
  })]) : _vm._e()])]) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }