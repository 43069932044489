<template>
    <Card class="BaseDashboardTable">
        <div class="BaseDashboardTable-header">
            <div class="BaseDashboardTable-title">
                <slot name="title"></slot>
                <span class="item-counter" v-if="filteredItems.length > 0 && showItemCounter"
                    >{{ filteredItems.length }}
                    {{ filteredItems.length === 1 ? 'Eintrag' : 'Einträge' }}</span
                >
            </div>
            <div class="BaseDashboardTable-actions">
                <div v-if="hasSearch" class="search-container">
                    <i class="ri-search-line search-icon"></i>
                    <input
                        type="text"
                        class="search-input"
                        :placeholder="searchPlaceholder"
                        @input="debouncedSearch($event.target.value)"
                    />
                </div>
                <slot name="actions"></slot>
            </div>
        </div>
        <div
            class="BaseDashboardTable-container"
            ref="tableContainer"
            :style="{ height: containerHeight }"
        >
            <div class="table-scroll-container" ref="scrollContainer">
                <table :style="{ minWidth: tableMinWidth }">
                    <thead>
                        <tr>
                            <th
                                v-if="showCounter"
                                class="counter-column text-center"
                                style="width: 50px; min-width: 50px;"
                            >
                                #
                            </th>
                            <th
                                v-for="(column, index) in columns"
                                :key="index"
                                :class="[
                                    column.align ? `text-${column.align}` : '',
                                    { sortable: column.sortable !== false },
                                    { sorted: currentSort.column === column.key },
                                    {
                                        'sorted-asc':
                                            currentSort.column === column.key &&
                                            currentSort.ascending,
                                    },
                                    {
                                        'sorted-desc':
                                            currentSort.column === column.key &&
                                            !currentSort.ascending,
                                    },
                                ]"
                                :style="getColumnStyle(column)"
                                @click="handleHeaderClick(column)"
                            >
                                <div class="column-header">
                                    {{ truncateText(column.label, headerTruncateLength) }}
                                    <i
                                        v-if="column.sortable !== false"
                                        :class="[
                                            currentSort && currentSort.column === column.key
                                                ? currentSort.ascending
                                                    ? 'ri-arrow-up-s-line active'
                                                    : 'ri-arrow-down-s-line active'
                                                : 'ri-arrow-up-down-line',
                                        ]"
                                    ></i>
                                </div>
                            </th>

                            <th v-if="selectable" class="checkbox-column">
                                {{ selectColumnLabel }}
                                <input
                                    type="checkbox"
                                    :checked="allSelected"
                                    @change="toggleSelectAll"
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            class="top-spacer"
                            v-if="startIndex > 0"
                            :style="{ height: topSpacerHeight + 'px' }"
                        ></tr>
                        <!-- Only render visible items -->
                        <template v-for="(item, index) in visibleItems">
                            <BaseDashboardTableRow
                                :key="`${item._id || index}-${startIndex + index}`"
                                :item="item"
                                :index="startIndex + index + 1"
                                :show-counter="showCounter"
                                :columns="columns"
                                :is-clickable="isClickable"
                                :selectable="selectable"
                                :has-any-expandable-rows="hasAnyExpandableRows"
                                :is-checked="isChecked(item)"
                                :expanded="isRowExpanded(item)"
                                :is-selected="isSelected(item)"
                                :cell-truncate-length="cellTruncateLength"
                                :global-components="globalComponents"
                                @row-click="
                                    handleRowClick(
                                        $event.item,
                                        startIndex + $event.index,
                                        $event.event,
                                    )
                                "
                                @row-hover="handleRowHover($event.item, startIndex + $event.index)"
                                @row-leave="handleRowLeave($event.item, startIndex + $event.index)"
                                @toggle-expand="
                                    toggleExpand($event.item, startIndex + $event.index)
                                "
                                @item-checked="toggleItemChecked"
                            >
                                <template
                                    v-for="column in columns"
                                    #[`column-${column.key}`]="slotProps"
                                >
                                    <slot :name="`column-${column.key}`" v-bind="slotProps"></slot>
                                </template>
                            </BaseDashboardTableRow>

                            <tr
                                v-if="!useHierarchicalRows && isRowExpanded(item)"
                                :key="`${startIndex + index}-expanded`"
                                class="nested-table-row"
                            >
                                <td :colspan="columns.length">
                                    <div class="nested-table-container">
                                        <slot
                                            name="nested-table"
                                            :item="item"
                                            :index="startIndex + index"
                                        ></slot>
                                    </div>
                                </td>
                            </tr>
                        </template>

                        <!-- Virtual scroll placeholder for bottom items -->
                        <tr
                            class="bottom-spacer"
                            v-if="endIndex < filteredItems.length"
                            :style="{ height: bottomSpacerHeight + 'px' }"
                        ></tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div v-if="!filteredItems.length" class="table-empty">
            <i class="ri-inbox-line"></i>
            <p>{{ searchQuery ? 'Keine Ergebnisse gefunden.' : emptyMessage }}</p>
        </div>
    </Card>
</template>

<script>
import Card from '@/components/Card';
import Checkbox from '@/components/widgets/Checkbox';
import BaseDashboardTableRow from '@/components/BaseDashboardTableRow';
import Download from '@/components/widgets/Download';
import { fakerDE as faker } from '@faker-js/faker';

export default {
    name: 'BaseDashboardTable',
    components: {
        Card,
        Checkbox,
        Download,
        BaseDashboardTableRow,
    },
    props: {
        showItemCounter: {
            type: Boolean,
            default: true,
        },
        columns: {
            type: Array,
            required: true,
        },
        items: {
            type: Array,
            default: () => [],
        },
        sort: {
            type: Object,
            default: null,
        },
        cellTruncateLength: {
            type: Number,
            default: 100,
        },
        headerTruncateLength: {
            type: Number,
            default: 20,
        },
        selectedFn: {
            type: Function,
            default: () => false,
        },
        isClickable: {
            type: Boolean,
            default: true,
        },
        emptyMessage: {
            type: String,
            default: 'Noch keine Daten vorhanden.',
        },
        selectable: {
            type: Boolean,
            default: false,
        },
        checkedItems: {
            type: Array,
            default: () => [],
        },
        itemKey: {
            type: String,
            default: null,
        },
        selectAllByDefault: {
            type: Boolean,
            default: false,
        },
        selectColumnLabel: {
            type: String,
            default: 'Akzeptiert',
        },
        globalComponents: {
            type: Object,
            default: () => ({}),
        },
        useHierarchicalRows: {
            type: Boolean,
            default: false,
        },
        containerHeight: {
            type: String,
            default: 'auto',
        },
        hasSearch: {
            type: Boolean,
            default: true,
        },
        searchPlaceholder: {
            type: String,
            default: 'Suchen...',
        },
        searchableColumns: {
            type: Array,
            default: () => [],
        },
        showCounter: {
            type: Boolean,
            default: false,
        },
        itemHeight: {
            type: Number,
            default: 40,
        },
        bufferSize: {
            type: Number,
            default: 10,
        },
        useItemVirtualization: {
            type: Boolean,
            default: false,
        },
        virtualizationThreshold: {
            type: Number,
            default: 100,
        },
        useFakeData: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            currentSort: { ...this.sort },
            localCheckedItems: [],
            searchQuery: '',
            tableMinWidth: 0,
            debouncedSearch: null,
            // Virtual scrolling state
            startIndex: 0,
            endIndex: 0,
            visibleHeight: 0,
            scrollTop: 0,
            lastScrollTime: 0,
            scrollThrottleTime: 16, // ~60fps
        };
    },
    computed: {
        shouldUseVirtualization() {
            return this.filteredItems.length >= this.virtualizationThreshold;
        },
        itemsWithFakeData() {
            if (!this.useFakeData) {
                return this.items;
            }

            return this.items.map(item => {
                const itemCopy = { ...item };
                console.log(itemCopy);
                if ('driverName' in item) {
                    itemCopy.driverName = faker.person.fullName();
                }
                if ('name' in item) {
                    itemCopy.name = faker.person.fullName();
                }
                if ('licenseNumber' in item) {
                    itemCopy.licenseNumber = 'B-' + faker.string.alphanumeric(6).toUpperCase();
                }

                return itemCopy;
            });
        },
        filteredItems() {
            const result = !this.searchQuery
                ? this.itemsWithFakeData
                : this.itemsWithFakeData.filter(item => {
                      const searchableColumns =
                          this.searchableColumns.length > 0
                              ? this.searchableColumns
                              : this.columns.map(col => col.key);

                      return searchableColumns.some(columnKey => {
                          const value = this.getItemValue(item, { key: columnKey });
                          if (value == null) return false;
                          return String(value)
                              .toLowerCase()
                              .includes(this.searchQuery.toLowerCase());
                      });
                  });

            console.log('Filtered items computed:', result.length);
            return result;
        },
        visibleItems() {
            // If we don't need virtualization, return all filtered items
            if (!this.shouldUseVirtualization) {
                return this.filteredItems;
            }

            // Otherwise, return only items in the current view range
            return this.filteredItems.slice(this.startIndex, this.endIndex);
        },

        topSpacerHeight() {
            // Only add spacer height if virtualization is enabled
            return this.shouldUseVirtualization ? this.startIndex * this.itemHeight : 0;
        },

        bottomSpacerHeight() {
            // Only add spacer height if virtualization is enabled
            if (!this.shouldUseVirtualization) return 0;

            const remainingItems = this.filteredItems.length - this.endIndex;
            return remainingItems > 0 ? remainingItems * this.itemHeight : 0;
        },
        allSelected() {
            return (
                this.filteredItems.length > 0 &&
                this.filteredItems.every(item => this.isChecked(item))
            );
        },
        hasAnyExpandableRows() {
            return this.filteredItems.some(item => this.hasChildren(item));
        },
    },
    created() {
        console.log('BaseDashboardTable created with items:', this.items.length);

        if (
            this.selectAllByDefault &&
            this.filteredItems.length > 0 &&
            this.localCheckedItems.length === 0
        ) {
            this.localCheckedItems = [...this.filteredItems];
        } else {
            this.localCheckedItems = [...this.checkedItems];
        }

        // Create debounced search function
        this.debouncedSearch = this.debounce(value => {
            this.searchQuery = value;
        }, 300);
    },
    watch: {
        filteredItems() {
            // Recalculate visible range when data changes
            this.$nextTick(() => {
                this.calculateVisibleItems();
            });
        },
        sort: {
            handler(newSort) {
                this.currentSort = { ...newSort };
            },
            deep: true,
        },
        checkedItems: {
            handler(newVal) {
                this.localCheckedItems = [...newVal];
            },
            deep: true,
        },
        items: {
            handler(newItems) {
                if (
                    this.selectAllByDefault &&
                    newItems.length > 0 &&
                    this.localCheckedItems.length === 0
                ) {
                    this.localCheckedItems = [...newItems];
                    this.$emit('checked-changed', this.localCheckedItems);
                }
            },
            immediate: true,
        },
    },
    mounted() {
        console.log('BaseDashboardTable mounted with filteredItems:', this.filteredItems.length);

        this.handleResize = this.debounce(() => {
            this.$forceUpdate();
            // Recalculate table dimensions on resize
            this.getTableMinWidth();
        }, 150);

        document.querySelector('.Wrap > span').addEventListener('scroll', this.handleScroll);
        this.getTableMinWidth();
        this.calculateVisibleItems();
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        console.log('BaseDashboardTable beforeDestroy - cleaning up');

        // Clean up resize listener
        if (this.handleResize) {
            window.removeEventListener('resize', this.handleResize);
        }
    },
    methods: {
        calculateVisibleItems() {
            // Skip calculation if virtualization is not needed
            if (!this.shouldUseVirtualization) {
                this.startIndex = 0;
                this.endIndex = this.filteredItems.length;
                return;
            }

            const scrollContainer = document.querySelector('.Wrap > span');
            if (!scrollContainer) return;

            // Get the container's viewport height
            this.visibleHeight = window.innerHeight;

            // Calculate how many items can fit in the viewport plus buffer
            const visibleItemCount =
                Math.ceil(this.visibleHeight / this.itemHeight) + this.bufferSize * 2;

            // Calculate the visible range based on scroll position
            const scrollTop = scrollContainer.scrollTop;
            const startIndex = Math.max(
                0,
                Math.floor(scrollTop / this.itemHeight) - this.bufferSize,
            );
            const endIndex = Math.min(this.filteredItems.length, startIndex + visibleItemCount);

            // Update the indices
            this.startIndex = startIndex;
            this.endIndex = endIndex;
            this.scrollTop = scrollTop;

            console.log(
                `Rendering rows ${this.startIndex} to ${this.endIndex} of ${this.filteredItems.length}`,
            );
        },

        handleScroll(event) {
            // Skip handling if virtualization is not needed
            if (!this.shouldUseVirtualization) return;

            // Throttle scroll events for better performance
            const now = Date.now();
            if (now - this.lastScrollTime > this.scrollThrottleTime) {
                this.lastScrollTime = now;
                this.calculateVisibleItems();
            }
        },

        handleResize: function() {
            this.debouncedResizeHandler();
        },

        debouncedResizeHandler: function() {
            if (this.resizeTimeout) {
                clearTimeout(this.resizeTimeout);
            }

            this.resizeTimeout = setTimeout(() => {
                this.getTableMinWidth();
                this.calculateVisibleItems();
                this.$forceUpdate();
            }, 150);
        },
        debounce(fn, delay) {
            let timeoutId;
            return function(...args) {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => fn.apply(this, args), delay);
            };
        },

        throttle(fn, delay = 16) {
            let lastCall = 0;
            return function(...args) {
                const now = Date.now();
                if (now - lastCall >= delay) {
                    fn.apply(this, args);
                    lastCall = now;
                }
            };
        },

        getColumnStyle(column, item) {
            const style = {};

            if (column.priority) {
                style['data-priority'] = column.priority;
            }

            // Simplified width handling - prioritize width property
            if (column.width) {
                style.width = column.width;
            } else {
                style.width = 'auto';
            }

            // Only apply mobile overrides when needed
            if (window.innerWidth < 756) {
                style.maxWidth = 'unset';
                style.minWidth = 'unset';
                style.width = 'unset';
            }

            // Additional styling for right-aligned columns
            if (column.align === 'right') {
                style.whiteSpace = 'nowrap';
            }

            return style;
        },

        isSelected(item) {
            return this.selectedFn(item);
        },

        handleHeaderClick(column) {
            if (column.sortable === false) return;

            if (this.currentSort.column === column.key) {
                this.currentSort.ascending = !this.currentSort.ascending;
            } else {
                this.currentSort.column = column.key;
                this.currentSort.ascending = true;
            }

            this.$emit('sort-changed', { ...this.currentSort });
        },

        handleRowClick(item, index, event) {
            // Skip if the item is a gap row
            if (item._isGap) return;

            // Don't handle click if the row isn't clickable
            if (!this.isClickable) return;

            // If the click target is an interactive element (except expand toggle), don't handle the row click
            const isInteractiveElement = event.target.closest('button, input, a, [role="button"]');

            if (isInteractiveElement && !isInteractiveElement.classList.contains('expand-toggle')) {
                return;
            }

            // Emit row click event
            this.$emit('row-click', { item, index, event });
        },

        handleRowHover(item, index) {
            this.$emit('row-hover', { item, index });
        },

        handleRowLeave(item, index) {
            this.$emit('row-leave', { item, index });
        },

        getItemValue(item, column) {
            let value;

            if (column.key.includes('.')) {
                const keys = column.key.split('.');
                value = item;
                for (const key of keys) {
                    if (value === null || value === undefined) return '';
                    value = value[key];
                }
            } else if (column.formatter && typeof column.formatter === 'function') {
                value = column.formatter(item[column.key], item);
            } else {
                value = item[column.key];
            }

            return value;
        },

        truncateText(text, maxLength = 30) {
            // Handle null, undefined, or non-string values
            if (text === null || text === undefined) return '';

            // Convert to string if it's not already
            const stringValue = String(text);

            // If text is already shorter than maxLength, return it as is
            if (stringValue.length <= maxLength) return stringValue;

            // Otherwise, truncate and add ellipsis
            return stringValue.substring(0, maxLength - 3) + '...';
        },

        getColumnTooltip(item, column) {
            // Get the raw value
            const value = this.getItemValue(item, column);

            // If the value is a string and would be truncated, show the full text in tooltip
            if (value !== null && value !== undefined) {
                const stringValue = String(value);
                if (stringValue.length > this.cellTruncateLength) {
                    return stringValue;
                }
            }

            // Otherwise use configured tooltip
            if (column.tooltip) {
                if (typeof column.tooltip === 'function') {
                    return column.tooltip(item);
                }
                return column.tooltip;
            }

            return null;
        },

        isLongContent(content) {
            if (typeof content === 'string') {
                return content.length > 15;
            }
            return false;
        },

        isChecked(item) {
            if (this.itemKey) {
                return this.localCheckedItems.some(i => i[this.itemKey] === item[this.itemKey]);
            }
            return this.localCheckedItems.includes(item);
        },

        toggleCheck(item) {
            let updatedItems;

            if (this.isChecked(item)) {
                if (this.itemKey) {
                    updatedItems = this.localCheckedItems.filter(
                        i => i[this.itemKey] !== item[this.itemKey],
                    );
                } else {
                    updatedItems = this.localCheckedItems.filter(i => i !== item);
                }
            } else {
                updatedItems = [...this.localCheckedItems, item];
            }

            this.localCheckedItems = updatedItems;
            this.$emit('checked-changed', updatedItems);
        },

        toggleSelectAll() {
            if (this.allSelected) {
                this.localCheckedItems = [];
            } else {
                this.localCheckedItems = [...this.items];
            }
            this.$emit('checked-changed', this.localCheckedItems);
        },

        resolveComponent(component) {
            if (typeof component === 'string') {
                if (this.globalComponents && this.globalComponents[component]) {
                    return this.globalComponents[component];
                }

                if (this.$root.$options.components && this.$root.$options.components[component]) {
                    return component;
                }

                const parentComponents = this.$parent.$options.components || {};
                if (parentComponents[component]) {
                    return component;
                }

                console.warn(`Component "${component}" not found`);
                return 'div';
            }

            return component;
        },

        getComponentProps(column, item) {
            const defaultProps = {
                value: this.getItemValue(item, column),
                item: item,
                column: column,
            };

            if (column.props) {
                if (typeof column.props === 'function') {
                    return { ...defaultProps, ...column.props(item) };
                }
                return { ...defaultProps, ...column.props };
            }

            return defaultProps;
        },

        isRowExpanded(item) {
            return item._expanded === true;
        },

        hasChildren(item) {
            console.log(
                'Checking if row has children:',
                item._id || item.id,
                item.children && item.children.length > 0,
                item._hasChildren,
            );
            return (item.children && item.children.length > 0) || item._hasChildren === true;
        },

        toggleExpand(item, index) {
            console.log('Toggling expand state for item:', item._id || item.id);
            item._expanded = !item._expanded;
            this.$emit('toggle-expand', { item, index, expanded: item._expanded });
        },

        toggleItemChecked(item) {
            if (!this.selectable) return;
            this.toggleCheck(item);
        },

        getTableMinWidth() {
            // Calculate minimum width based on column definitions
            let minWidth = 0;

            // Process each column
            this.columns.forEach(column => {
                // Extract numeric value from width if it's a percentage or pixel value
                let columnWidth = 0;

                if (column.width) {
                    if (typeof column.width === 'string') {
                        if (column.width.endsWith('px')) {
                            // For pixel values, use the exact value
                            columnWidth = parseInt(column.width, 10);
                        } else if (column.width.endsWith('%')) {
                            // For percentage values, use a reasonable minimum (80px)
                            columnWidth = 80;
                        }
                    }
                }

                // If no width specified or couldn't parse, use default minimum
                if (columnWidth <= 0) {
                    columnWidth = 80; // Default minimum column width
                }

                columnWidth = Math.min(columnWidth, 140);

                minWidth += columnWidth;
            });

            // Add a small buffer
            minWidth += 20;

            // Check if the DOM reference is available
            const availableSpace = this.$refs.tableContainer
                ? this.$refs.tableContainer.clientWidth
                : 0;

            this.tableMinWidth = `${Math.max(minWidth, availableSpace - 10)}px`;
        },
    },
};
</script>

<style lang="scss">
.BaseDashboardTable {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 0.85rem;
    padding: 4px;
    height: 100%;
    position: relative;

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 6px 8px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);

        &-title {
            margin: 0;
            font-size: 0.75rem;
            font-weight: 600;
            color: var(--color-text-black);
            white-space: normal;
            display: flex;
            align-items: center;

            .item-counter {
                margin-left: 8px;
                font-size: 0.7rem;
                color: var(--color-gray);
                padding: 2px 6px;
                border-radius: 10px;
                white-space: nowrap;
            }
        }

        &-actions {
            display: flex;
            gap: 4px;
        }

        .search-container {
            position: relative;
            margin-right: 8px;

            .search-icon {
                position: absolute;
                left: 8px;
                top: 50%;
                transform: translateY(-50%);
                color: var(--color-gray);
                font-size: 1rem;
            }

            .search-input {
                padding: 6px 8px 6px 32px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 4px;
                font-size: 0.85rem;
                width: 200px;
                color: var(--color-text-black);
                background-color: var(--color-white);
                transition: all 0.2s ease;

                &:focus {
                    outline: none;
                    border-color: var(--color-blue);
                    box-shadow: 0 0 0 2px rgba(var(--color-blue-rgb), 0.6);
                }

                &::placeholder {
                    color: rgba(var(--color-blue-rgb), 0.7);
                }
            }
        }
    }

    &-container {
        position: relative;
        flex: 1;
        min-height: 100px;
        display: flex;
        flex-direction: column;
        will-change: contents;

        .table-scroll-container {
            height: 100%;
            width: 100%;
            overflow-y: auto;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            position: relative;
        }

        // Add transitions to the placeholder rows for smoother updates
        .placeholder-row {
            transition: height 0.15s ease;
        }

        table {
            table-layout: fixed;
            border-collapse: collapse;
            thead {
                position: sticky;
                top: 0;
                background-color: var(--color-white);
                z-index: 9;

                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: 1px;
                    background-color: rgba(0, 0, 0, 0.1);
                }

                tr {
                    background-color: var(--color-white);
                    th {
                        padding: 8px;
                        font-size: 0.8rem;
                        font-weight: 600;
                        color: var(--color-gray);
                        text-align: left;
                        white-space: nowrap;
                        overflow: visible;

                        &.align-right {
                            text-align: right;
                        }

                        &.align-center {
                            text-align: center;
                        }

                        &.sortable {
                            cursor: pointer;
                            user-select: none;

                            &:hover {
                                color: var(--color-text-black);
                                background-color: rgba(0, 0, 0, 0.02);
                            }
                        }

                        &.sorted {
                            color: var(--color-text-blue-dark);
                        }

                        .column-header {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;

                            i {
                                margin-left: 4px;
                                opacity: 0.3;
                                font-size: 0.9rem;

                                &.active {
                                    opacity: 1;
                                    color: var(--color-blue);
                                }
                            }
                        }
                    }
                }
            }

            tbody {
                tr {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
                    border-left: 2px solid transparent;
                    transition: background-color 0.15s ease, border-left-color 0.15s ease;
                    background-color: var(--color-white);
                    contain: layout;

                    &:nth-child(odd) {
                        background-color: rgb(245 245 245);
                    }

                    &.clickable {
                        cursor: pointer;
                    }

                    &:hover {
                        background-color: rgb(235, 235, 235) !important;
                        border-left-color: rgba(var(--color-blue-rgb), 0.3);
                    }

                    &.selected {
                        background-color: rgb(205, 205, 205);
                        border-left: 2px solid var(--color-blue);
                    }

                    &.selected:hover {
                        background-color: rgb(225, 225, 225);
                    }

                    &.parent-row {
                        font-weight: 500;
                        position: relative;

                        &.expanded {
                            border-left: 2px solid var(--color-blue);
                            background-color: rgba(var(--color-blue-rgb), 0.05);

                            &::after {
                                display: none;
                            }
                        }
                    }

                    &.child-row {
                        background-color: rgba(var(--color-blue-rgb), 0.02);
                        border-left: none;
                        position: relative;

                        &:nth-child(even) {
                            background-color: rgba(var(--color-blue-rgb), 0.07);
                        }

                        &:last-child {
                            &::before {
                                bottom: 50%;
                            }
                        }

                        &:hover {
                            background-color: rgba(var(--color-blue-rgb), 0.08) !important;
                        }

                        &::before {
                            content: '';
                            position: absolute;
                            left: 19px;
                            top: 0;
                            bottom: 0;
                            width: 2px;
                            background-color: var(--color-border, #e0e0e0);
                        }

                        &::after {
                            content: '';
                            position: absolute;
                            left: 19px;
                            top: 50%;
                            width: 20px;
                            height: 2px;
                            background-color: var(--color-border, #e0e0e0);
                        }

                        &:has(+ .parent-row)::before {
                            bottom: 50%;
                        }

                        & + .parent-row {
                            & ~ .child-row::before {
                                top: -50%;
                            }
                        }
                    }

                    td {
                        padding: 4px 8px;
                        font-size: 0.8rem;
                        color: var(--color-text-black);
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        min-width: 50px;

                        &.text-right {
                            > * {
                                text-align: right;
                                display: flex;
                                justify-content: flex-end;
                            }
                        }
                        &.text-left {
                            > * {
                                text-align: left;
                                display: flex;
                                justify-content: flex-start;
                            }
                        }
                        &.text-center {
                            > * {
                                text-align: center;
                                display: flex;
                                justify-content: center;
                            }
                        }
                        > span {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            min-width: 50px;
                        }

                        &.first-column {
                            display: flex;
                            align-items: center;
                            gap: 4px;

                            .expand-toggle,
                            .expand-toggle-spacer {
                                display: inline-flex;
                                align-items: center;
                                justify-content: center;
                                min-width: 20px;
                                width: 20px;
                                height: 20px;
                                margin-right: 4px;
                                flex-shrink: 0;
                            }

                            .expand-toggle {
                                color: var(--color-blue);
                                cursor: pointer;
                                border-radius: 2px;
                                transition: all 0.2s ease;

                                &:hover {
                                    background-color: rgba(var(--color-blue-rgb), 0.1);
                                    transform: scale(1.1);

                                    i {
                                        opacity: 1;
                                        color: var(--color-blue);
                                    }
                                }

                                i {
                                    font-size: 18px;
                                    display: block;
                                    opacity: 0.7;
                                    transition: all 0.2s ease;
                                }
                            }
                        }

                        &.indented {
                            .indentation {
                                display: inline-block;
                                width: calc(20px + 4px);
                                margin-right: 4px;
                            }

                            padding-left: calc(8px + (var(--indent-level, 0) * 24px));
                        }

                        &.align-right {
                            text-align: right;
                            font-feature-settings: 'tnum';
                            font-variant-numeric: tabular-nums;
                            padding-right: 10px;
                        }

                        &.align-center {
                            text-align: center;
                        }

                        &.has-long-content {
                            position: relative;
                        }

                        .child-row & {
                            white-space: normal;
                            line-height: 1.3;

                            &.date-column {
                                white-space: nowrap;
                            }
                        }
                    }
                }

                .nested-table-row {
                    background-color: rgba(var(--color-blue-rgb), 0.02);

                    td {
                        padding: 0;
                    }
                }
            }
        }
    }
    .top-spacer,
    .bottom-spacer {
        min-height: 1px;
        pointer-events: none;
        background-color: transparent !important;
        border: none;

        &:hover {
            background-color: transparent !important;
            border-left-color: transparent;
        }
    }
    .table-empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
        color: var(--color-gray);

        i {
            font-size: 1.8rem;
            margin-bottom: 8px;
            opacity: 0.5;
        }

        p {
            font-size: 0.8rem;
            font-style: italic;
            margin: 0;
        }
    }

    @media screen and (max-width: 768px) {
        &-container {
            .table-scroll-container {
                -webkit-overflow-scrolling: touch;
            }

            table {
                th:first-child,
                td:first-child {
                    position: sticky;
                    left: 0;
                    max-width: 120px !important;
                    background-color: inherit;
                }

                td {
                    padding: 8px;

                    .expand-toggle {
                        min-width: 28px;
                        width: 28px;
                        height: 28px;
                    }
                }
            }
        }

        &-header {
            flex-wrap: wrap;

            &-actions {
                margin-top: 4px;
            }
        }
    }

    @media screen and (max-width: 480px) {
        &-container table {
            th:first-child,
            td:first-child {
                max-width: 100px !important;
            }

            td {
                padding: 10px 8px;
            }
        }

        &-header {
            flex-direction: column;
            align-items: flex-start;

            &-actions {
                margin-top: 8px;
                width: 100%;
                justify-content: flex-end;
            }
        }
    }

    .table-container table {
        td[data-priority='high'],
        th[data-priority='high'] {
            min-width: 80px;
        }
    }

    &.compact-mode {
        .table-scroll-container {
            overflow-y: auto;
        }

        &-header {
            padding: 4px 6px;

            &-title {
                font-size: 0.85rem;
            }
        }

        thead th {
            padding: 4px 6px;
            font-size: 0.7rem;
        }

        tbody td {
            padding: 3px 6px;
            font-size: 0.75rem;

            span {
                max-width: 100px;
            }
        }
    }

    .placeholder-row {
        display: table-row;
        height: 35px;
        border: none;
        pointer-events: none;
        opacity: 0;

        // Prevent placeholders from showing zebra striping
        &:nth-child(odd),
        &:nth-child(even) {
            background-color: transparent !important;
        }
    }

    .counter-column {
        position: sticky;
        left: 0;
        z-index: 2;
    }
}
</style>
