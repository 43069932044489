var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseDashboardTable', {
    attrs: {
      "columns": _vm.columns,
      "items": _vm.sortedWorkingTimes,
      "sort": _vm.sortState,
      "empty-message": 'Keine Arbeitszeiten gefunden'
    },
    on: {
      "sort-changed": _vm.handleSortChanged,
      "row-click": _vm.handleRowClick,
      "row-hover": _vm.handleRowHover,
      "row-leave": _vm.handleRowLeave
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('h3', [_vm._v("Arbeitszeiten")])];
      },
      proxy: true
    }, {
      key: "row-badge",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm.hasPauses(item) ? _c('span', {
          staticClass: "pause-badge"
        }, [_vm._v("P")]) : _vm._e()];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }