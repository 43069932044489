<template>
    <div
        class="Checkbox"
        :class="[
            { 'Checkbox--hasError': hasError, 'Checkbox--disabled': isDisabled },
            `Checkbox--${size}`,
        ]"
    >
        <input
            type="checkbox"
            :id="computedId"
            :name="name"
            :disabled="isDisabled"
            :checked="value"
            @change="handleChange"
            class="Checkbox-input"
        />
        <label :for="computedId" class="Checkbox-label">
            <div class="Checkbox-indicator">
                <i v-if="value" class="ri-check-line"></i>
            </div>
            <div class="Checkbox-content" v-if="$slots.default">
                <slot />
            </div>
        </label>
    </div>
</template>

<script>
export default {
    props: {
        size: {
            type: String,
            default: 'medium',
        },
        name: String,
        hasError: Boolean,
        isDisabled: Boolean,
        value: Boolean,
        keyName: {
            type: String,
            default: 'value',
        },
        onCheck: {
            type: Function,
            default: () => {},
        },
        onChange: {
            type: Function,
            default: null,
        },
    },
    computed: {
        computedId() {
            return this.name || `checkbox-${this._uid}`;
        },
    },
    methods: {
        handleChange(event) {
            const isChecked = event.target.checked;

            // Emit the changed value to the parent with multiple event names for compatibility
            this.$emit('onCheck', isChecked);
            this.$emit('onChange', isChecked);
            this.$emit('update:value', isChecked);
            this.$emit('input', isChecked); // For v-model support

            // Call callback functions if provided
            if (typeof this.onCheck === 'function') {
                this.onCheck(isChecked);
            }

            if (typeof this.onChange === 'function') {
                this.onChange(isChecked);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.Checkbox {
    display: inline-flex;
    align-items: center;
    position: relative;
    margin: 4px 0;

    &-input {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;

        &:focus + .Checkbox-label .Checkbox-indicator {
            box-shadow: 0 0 0 2px rgba(var(--color-blue-light, 33, 150, 243), 0.3);
        }
    }

    &-label {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        user-select: none;
    }

    &-indicator {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        background-color: white;
        border: 2px solid var(--color-gray, #95a5a6);
        border-radius: 4px;
        margin-right: 8px;
        transition: all 0.2s ease;

        i {
            color: white;
            font-size: 16px;
            line-height: 1;
        }
    }

    &-content {
        font-size: 14px;
        line-height: 1.5;
        color: var(--color-text, #333);
    }

    // Checked state
    &-input:checked + .Checkbox-label .Checkbox-indicator {
        background-color: var(--color-blue, #1976d2);
        border-color: var(--color-blue, #1976d2);
    }

    // Disabled state
    &--disabled {
        opacity: 0.6;

        .Checkbox-label {
            cursor: not-allowed;
        }

        .Checkbox-indicator {
            background-color: #f5f5f5;
        }
    }

    // Error state
    &--hasError .Checkbox-indicator {
        border-color: var(--color-red, #e74c3c);
    }

    // Size variations
    &--small {
        margin: 2px 0;

        .Checkbox-indicator {
            width: 16px;
            height: 16px;

            i {
                font-size: 14px;
            }
        }

        .Checkbox-content {
            font-size: 12px;
        }
    }

    &--medium {
        // Default size, already defined
    }

    &--large {
        .Checkbox-indicator {
            width: 24px;
            height: 24px;

            i {
                font-size: 20px;
            }
        }

        .Checkbox-content {
            font-size: 16px;
        }
    }


}
</style>
