var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.rows ? _c('div', {
    ref: "row",
    staticClass: "Row",
    class: _vm.rowClasses,
    on: {
      "click": _vm.onClick
    }
  }, [_c('div', _vm._b({
    ref: "parent",
    staticClass: "Parent",
    class: _vm.parentClasses,
    style: _vm.computedParentStyle,
    on: {
      "mouseenter": function mouseenter($event) {
        return _vm.onMouseEnterParent(_vm.index);
      },
      "mouseleave": function mouseleave($event) {
        return _vm.onMouseLeaveParent(_vm.index);
      },
      "click": _vm.onClickParent
    }
  }, 'div', _vm.items.props || {}, false), [_vm._l(_vm.rows, function (row, i) {
    return [_c('Column', {
      key: i,
      attrs: {
        "item": row,
        "index": _vm.index,
        "childIndex": i,
        "hasHeader": _vm.hasHeader
      },
      on: {
        "onClick": function onClick($event) {
          return _vm.$emit('onClickColumn', $event);
        }
      }
    })];
  }), _vm._t("parent-additional"), _vm.hasChildren ? _c('span', {
    staticClass: "Icon-close"
  }) : _vm._e()], 2), _vm.items.children ? _c('div', {
    ref: "children",
    staticClass: "Children",
    class: {
      'is-hidden': _vm.isHidden
    }
  }, [_vm._t("row-additional"), _vm.items.children.length > 0 ? _c('div', {
    staticClass: "nested-header-wrapper"
  }, [_vm.items.children[0] ? _c('Row', {
    staticClass: "Row-Header",
    attrs: {
      "parentHover": false,
      "items": _vm.items.children[0],
      "hasHeader": true,
      "index": 0,
      "hasWarning": _vm.items.children[0].props && _vm.items.children[0].props.hasWarning
    },
    on: {
      "onClick": _vm.onClickChild,
      "mouseenter": function mouseenter($event) {
        return _vm.onMouseEnterChild(0);
      },
      "mouseleave": function mouseleave($event) {
        return _vm.onMouseLeaveChild(0);
      }
    }
  }) : _vm._e()], 1) : _vm._e(), _vm._l(_vm.items.children.slice(1), function (child, i) {
    return _c('Row', {
      key: i + 1,
      attrs: {
        "parentHover": false,
        "items": child,
        "hasHeader": false,
        "index": i + 1,
        "hasWarning": child.props && child.props.hasWarning
      },
      on: {
        "onClick": _vm.onClickChild,
        "mouseenter": function mouseenter($event) {
          return _vm.onMouseEnterChild(i + 1);
        },
        "mouseleave": function mouseleave($event) {
          return _vm.onMouseLeaveChild(i + 1);
        }
      }
    });
  })], 2) : _vm._e()]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }