var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "WorkingTime",
    class: {
      'is-loading': _vm.isFetchingData
    }
  }, [_c('Toolbar', [_c('div', [_c('Dropdown', {
    attrs: {
      "hasSearch": "",
      "placeholder": "Fahrer",
      "items": _vm.drivers.filter(function (d) {
        return d.isVisible;
      }),
      "selected": _vm.selectedEmployee
    },
    on: {
      "onItemSelect": _vm.handleDriverChange
    }
  }), _c('Datepicker', {
    attrs: {
      "monthPicker": "",
      "startDate": _vm.selectedFrom
    },
    on: {
      "onChange": _vm.handleDateChange
    }
  }), _c('Button', {
    attrs: {
      "size": "small",
      "isLoading": _vm.isFetchingData
    },
    on: {
      "onClick": _vm.handleQuery
    }
  }, [_vm._v(" Anzeigen ")]), _c('PageNavigationToolbar', {
    attrs: {
      "selectedCar": _vm.selectedCar,
      "selectedEmployee": _vm.selectedEmployee
    }
  })], 1), _c('div', [_c('Download', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: !this.selectedEmployee ? 'Wählen Sie einen Fahrer aus' : '',
      expression: "!this.selectedEmployee ? 'Wählen Sie einen Fahrer aus' : ''"
    }],
    attrs: {
      "disabled": !this.selectedEmployee,
      "title": "Download PDF",
      "type": "pdf"
    },
    on: {
      "onDownload": _vm.handleDownload
    }
  })], 1)]), false ? _c('div', {
    staticClass: "OverviewCards"
  }, [_c('Card', {
    attrs: {
      "variant": "info"
    }
  }, [_c('p', [_vm._v("Zeitaufteilung (Gesamtwerte)")]), _c('h3', [_vm._v("Schichtzeit: " + _vm._s(_vm.timeToString(_vm.accumulatedShiftTime, {
    withLabel: true
  })))]), _c('h3', [_vm._v("Arbeitszeit: " + _vm._s(_vm.timeToString(_vm.accumulatedWorkTime, {
    withLabel: true
  })))]), _c('h3', [_vm._v("Pausenzeit: " + _vm._s(_vm.timeToString(_vm.accumulatedPauseTime, {
    withLabel: true
  })))])]), _c('Card', {
    attrs: {
      "variant": "info"
    }
  }, [_c('p', [_vm._v("Spezielle Arbeitszeiten (Gesamtwerte)")]), _c('h3', [_vm._v(" 00:00 - 04:00 Uhr: " + _vm._s(_vm.timeToString(_vm.accumulatedZeroToFour, {
    withLabel: true
  })) + " ")]), _c('h3', [_vm._v(" 08:00 - 18:00 Uhr: " + _vm._s(_vm.timeToString(_vm.accumulatedEightToSix, {
    withLabel: true
  })) + " ")]), _c('h3', [_vm._v("Sonntagsarbeit: " + _vm._s(_vm.timeToString(_vm.accumulatedSunday, {
    withLabel: true
  })))])]), _c('Card', {
    attrs: {
      "variant": "info"
    }
  }, [_c('p', [_vm._v("Feiertags-Arbeitszeiten (Gesamtwerte)")]), _c('h3', [_vm._v(" Feiertagsarbeit (125%): " + _vm._s(_vm.timeToString(_vm.accumulatedHoliday125, {
    withLabel: true
  })) + " ")]), _c('h3', [_vm._v(" Feiertagsarbeit (150%): " + _vm._s(_vm.timeToString(_vm.accumulatedHoliday150, {
    withLabel: true
  })) + " ")])])], 1) : _vm._e(), _vm.employeeWorkTime.length && _vm.selectedEmployee ? _c('employee-working-time-table', {
    attrs: {
      "workingTimes": this.employeeWorkTime
    },
    on: {
      "onClick": _vm.handleEmployeeRowClick
    }
  }) : _vm.workingTimes.length ? _c('working-time-table', {
    attrs: {
      "workingTimes": this.workingTimes
    },
    on: {
      "onClick": _vm.handleRowClick
    }
  }) : _vm.isFetchingData ? _c('div', {
    staticClass: "Spinner-Wrap"
  }, [_c('spinner')], 1) : _c('EmptyState'), _c('Modal', {
    attrs: {
      "title": _vm.getModalTitle(),
      "show": _vm.pauseModalIsActive,
      "action": {
        text: _vm.isSavingPauses ? 'Speichern...' : _vm.checkedPauses.length > 0 ? "".concat(_vm.checkedPauses.length, " Pause(n) speichern") : 'Speichern',
        color: 'green',
        callback: _vm.handleSavePauses,
        disabled: _vm.isSavingPauses || _vm.checkedPauses.length === 0
      },
      "cancel": {
        text: 'Abbrechen',
        disabled: _vm.isSavingPauses
      },
      "size": 'medium',
      "isLoading": _vm.isFetchingData
    },
    on: {
      "onModalClose": _vm.handleModalClose
    }
  }, [_c('BaseDashboardTable', {
    attrs: {
      "columns": _vm.pauseColumns,
      "items": _vm.formattedPauses,
      "sort": _vm.pauseSortState,
      "empty-message": 'Keine Pausen gefunden'
    },
    on: {
      "sort-changed": _vm.handlePauseSortChanged
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('h3', [_vm._v("Pausen")])];
      },
      proxy: true
    }, {
      key: "column-accepted",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('Checkbox', {
          attrs: {
            "value": _vm.isItemChecked(item),
            "size": "small"
          },
          on: {
            "onChange": function onChange(checked) {
              return _vm.handlePauseCheckChange(item, checked);
            }
          }
        })];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }