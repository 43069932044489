<template>
    <div class="WorkingTime" :class="{ 'is-loading': isFetchingData }">
        <Toolbar>
            <div>
                <Dropdown
                    hasSearch
                    placeholder="Fahrer"
                    :items="drivers.filter(d => d.isVisible)"
                    :selected="selectedEmployee"
                    @onItemSelect="handleDriverChange"
                />
                <Datepicker monthPicker :startDate="selectedFrom" @onChange="handleDateChange" />
                <Button size="small" @onClick="handleQuery" :isLoading="isFetchingData">
                    Anzeigen
                </Button>
                <PageNavigationToolbar
                    :selectedCar="selectedCar"
                    :selectedEmployee="selectedEmployee"
                />
            </div>
            <div>
                <Download
                    v-tooltip="!this.selectedEmployee ? 'Wählen Sie einen Fahrer aus' : ''"
                    :disabled="!this.selectedEmployee"
                    title="Download PDF"
                    type="pdf"
                    @onDownload="handleDownload"
                />
            </div>
        </Toolbar>
        <div v-if="false" class="OverviewCards">
            <Card variant="info">
                <p>Zeitaufteilung (Gesamtwerte)</p>
                <h3>Schichtzeit: {{ timeToString(accumulatedShiftTime, { withLabel: true }) }}</h3>
                <h3>Arbeitszeit: {{ timeToString(accumulatedWorkTime, { withLabel: true }) }}</h3>
                <h3>Pausenzeit: {{ timeToString(accumulatedPauseTime, { withLabel: true }) }}</h3>
            </Card>

            <Card variant="info">
                <p>Spezielle Arbeitszeiten (Gesamtwerte)</p>
                <h3>
                    00:00 - 04:00 Uhr:
                    {{ timeToString(accumulatedZeroToFour, { withLabel: true }) }}
                </h3>
                <h3>
                    08:00 - 18:00 Uhr:
                    {{ timeToString(accumulatedEightToSix, { withLabel: true }) }}
                </h3>
                <h3>Sonntagsarbeit: {{ timeToString(accumulatedSunday, { withLabel: true }) }}</h3>
            </Card>

            <Card variant="info">
                <p>Feiertags-Arbeitszeiten (Gesamtwerte)</p>
                <h3>
                    Feiertagsarbeit (125%):
                    {{ timeToString(accumulatedHoliday125, { withLabel: true }) }}
                </h3>
                <h3>
                    Feiertagsarbeit (150%):
                    {{ timeToString(accumulatedHoliday150, { withLabel: true }) }}
                </h3>
            </Card>
        </div>
        <employee-working-time-table
            v-if="employeeWorkTime.length && selectedEmployee"
            :workingTimes="this.employeeWorkTime"
            @onClick="handleEmployeeRowClick"
        />
        <working-time-table
            v-else-if="workingTimes.length"
            :workingTimes="this.workingTimes"
            @onClick="handleRowClick"
        />
        <div v-else-if="isFetchingData" class="Spinner-Wrap">
            <spinner />
        </div>
        <EmptyState v-else />
        <Modal
            :title="getModalTitle()"
            :show="pauseModalIsActive"
            :action="{
                text: isSavingPauses
                    ? 'Speichern...'
                    : checkedPauses.length > 0
                    ? `${checkedPauses.length} Pause(n) speichern`
                    : 'Speichern',
                color: 'green',
                callback: handleSavePauses,
                disabled: isSavingPauses || checkedPauses.length === 0,
            }"
            :cancel="{ text: 'Abbrechen', disabled: isSavingPauses }"
            :size="'medium'"
            :isLoading="isFetchingData"
            @onModalClose="handleModalClose"
        >
            <BaseDashboardTable
                :columns="pauseColumns"
                :items="formattedPauses"
                :sort="pauseSortState"
                :empty-message="'Keine Pausen gefunden'"
                @sort-changed="handlePauseSortChanged"
            >
                <template #title>
                    <h3>Pausen</h3>
                </template>

                <template #column-accepted="{ item }">
                    <Checkbox
                        :value="isItemChecked(item)"
                        size="small"
                        @onChange="checked => handlePauseCheckChange(item, checked)"
                    />
                </template>
            </BaseDashboardTable>
        </Modal>
    </div>
</template>
<script type="text/javascript">
import axios from 'axios';
import { endOfMonth, startOfMonth, format, parseISO, isValid } from 'date-fns';

import { getDuration, timeToString } from '@/lib/helper';

import Datepicker from '@/components/widgets/Datepicker';
import Download from '@/components/widgets/Download';
import Dropdown from '@/components/widgets/Dropdown';
import Modal from '@/components/widgets/Modal';
import Button from '@/components/widgets/Button';
import Headline from '@/components/Headline';
import Toolbar from '@/components/Toolbar';
import WorkingTimeTable from './components/WorkingTimeTable.vue';
import EmployeeWorkingTimeTable from './components/EmployeeWorkingTimeTable.vue';
import PageNavigationToolbar from '@/components/PageNavigationToolbar.vue';
import Spinner from '@/components/widgets/Spinner.vue';
import Row from '@/components/Row.vue';
import EmptyState from '@/components/EmptyState.vue';
import RowWrap from '@/components/RowWrap.vue';
import Card from '@/components/Card.vue';
import BaseDashboardTable from '@/components/BaseDashboardTable.vue';
import Checkbox from '@/components/widgets/Checkbox';

export default {
    name: 'AppNavigation',
    components: {
        Card,
        RowWrap,
        Modal,
        Datepicker,
        Download,
        Dropdown,
        Headline,
        Toolbar,
        WorkingTimeTable,
        Spinner,
        Button,
        Row,
        EmployeeWorkingTimeTable,
        EmptyState,
        PageNavigationToolbar,
        BaseDashboardTable,
        Checkbox,
    },

    data: () => {
        return {
            pauseModalIsActive: false,
            isFetchingData: false,
            isSavingPauses: false,
            employeeWorkTime: [],
            workingTimes: [],
            currentPauses: [],
            drivers: JSON.parse(localStorage.getItem('drivers')).filter(d => d.isVisible),
            selectedEmployee: null,
            selectedFrom: startOfMonth(new Date()),
            selectedTo: endOfMonth(new Date()),
            checkedPauses: [],
            pauseColumns: [
                { key: 'duration', label: 'DAUER (HH:MIN)', width: '140px' },
                { key: 'startTime', label: 'START', width: '140px' },
                { key: 'endTime', label: 'ENDE', width: '140px' },
                {
                    key: 'accepted',
                    label: 'Akzeptieren',
                    width: '120px',
                    align: 'center',
                    sortable: false,
                },
            ],
            pauseSortState: {
                column: 'startTime',
                ascending: true,
            },
            selectedCar: null,
        };
    },
    computed: {
        header() {
            return [
                { name: 'Dauer (HH:min)', value: null },
                { name: 'Start', value: null },
                { name: 'Ende', value: null },
                { name: 'Akzeptiert', value: null },
            ].map(header => {
                let indicator = '';
                if (this.pauseSortState.column === header.name) {
                    indicator = this.pauseSortState.ascending ? ' ▲' : ' ▼';
                }
                return { ...header, name: header.name + indicator };
            });
        },
        sortedPauses() {
            const { column, ascending } = this.pauseSortState;
            return [...this.currentPauses].sort((a, b) => {
                // Get values for the current column for comparison
                const getRawValue = pause => {
                    const entry = pause.row.find(item => item.name.startsWith(column));
                    return entry ? entry.rawValue : undefined;
                };

                const aVal = getRawValue(a);
                const bVal = getRawValue(b);

                if (aVal === undefined && bVal === undefined) return 0;
                if (aVal === undefined) return ascending ? 1 : -1;
                if (bVal === undefined) return ascending ? -1 : 1;
                if (aVal < bVal) return ascending ? -1 : 1;
                if (aVal > bVal) return ascending ? 1 : -1;
                return 0;
            });
        },
        // Shift time, either for a single driver (if selected) or for all drivers
        accumulatedShiftTime() {
            if (this.selectedEmployee) {
                // Sum up shift time for the selected employee
                return this.employeeWorkTime.reduce(
                    (total, workTime) => total + workTime.shiftTime,
                    0,
                );
            } else {
                // Sum up shift time for all drivers
                return this.workingTimes.reduce((total, workTime) => total + workTime.shiftTime, 0);
            }
        },

        // Work time, either for a single driver (if selected) or for all drivers
        accumulatedWorkTime() {
            if (this.selectedEmployee) {
                return this.employeeWorkTime.reduce(
                    (total, workTime) => total + workTime.workTime,
                    0,
                );
            } else {
                return this.workingTimes.reduce((total, workTime) => total + workTime.workTime, 0);
            }
        },

        // Pause time, either for a single driver (if selected) or for all drivers
        accumulatedPauseTime() {
            if (this.selectedEmployee) {
                return this.employeeWorkTime.reduce(
                    (total, workTime) => total + workTime.pauseTime,
                    0,
                );
            } else {
                return this.workingTimes.reduce((total, workTime) => total + workTime.pauseTime, 0);
            }
        },

        // Special work hours (00:00 - 04:00) for selected employee or all drivers
        accumulatedZeroToFour() {
            if (this.selectedEmployee) {
                return this.employeeWorkTime.reduce(
                    (total, workTime) => total + workTime.zeroToFour,
                    0,
                );
            } else {
                return this.workingTimes.reduce(
                    (total, workTime) => total + workTime.zeroToFour,
                    0,
                );
            }
        },

        // Special work hours (08:00 - 18:00) for selected employee or all drivers
        accumulatedEightToSix() {
            if (this.selectedEmployee) {
                return this.employeeWorkTime.reduce(
                    (total, workTime) => total + workTime.eightToSix,
                    0,
                );
            } else {
                return this.workingTimes.reduce(
                    (total, workTime) => total + workTime.eightToSix,
                    0,
                );
            }
        },

        // Sunday working hours for selected employee or all drivers
        accumulatedSunday() {
            if (this.selectedEmployee) {
                return this.employeeWorkTime.reduce(
                    (total, workTime) => total + workTime.sunday,
                    0,
                );
            } else {
                return this.workingTimes.reduce((total, workTime) => total + workTime.sunday, 0);
            }
        },

        // Holiday time at 125% pay for selected employee or all drivers
        accumulatedHoliday125() {
            if (this.selectedEmployee) {
                return this.employeeWorkTime.reduce(
                    (total, workTime) => total + workTime.holiday125,
                    0,
                );
            } else {
                return this.workingTimes.reduce(
                    (total, workTime) => total + workTime.holiday125,
                    0,
                );
            }
        },

        // Holiday time at 150% pay for selected employee or all drivers
        accumulatedHoliday150() {
            if (this.selectedEmployee) {
                return this.employeeWorkTime.reduce(
                    (total, workTime) => total + workTime.holiday150,
                    0,
                );
            } else {
                return this.workingTimes.reduce(
                    (total, workTime) => total + workTime.holiday150,
                    0,
                );
            }
        },
        formattedPauses() {
            if (!this.currentPauses || this.currentPauses.length === 0) {
                return [];
            }

            return this.currentPauses.map(pause => {
                // Safely parse dates with fallback
                let startDate, endDate;
                try {
                    startDate = pause.row[1].value ? new Date(pause.row[1].value) : new Date();
                    if (isNaN(startDate.getTime())) {
                        startDate = new Date();
                    }
                } catch (e) {
                    startDate = new Date();
                }

                try {
                    endDate = pause.row[2].value ? new Date(pause.row[2].value) : new Date();
                    if (isNaN(endDate.getTime())) {
                        endDate = new Date();
                    }
                } catch (e) {
                    endDate = new Date();
                }

                return {
                    pauseId: pause.id || `pause-${startDate.getTime()}`,
                    uuid: pause.uuid || pause.id,
                    startTime: format(startDate, 'dd.MM.yy HH:mm'),
                    endTime: format(endDate, 'dd.MM.yy HH:mm'),
                    duration: getDuration(pause.row[0].rawValue),
                    isAccepted: pause.isAccepted,
                    isActive: pause.isActive,

                    // Raw values for sorting
                    _startTime: startDate,
                    _endTime: endDate,
                    _duration: pause.row[0].rawValue,

                    // Original data reference
                    originalPause: pause,
                };
            });
        },
    },
    methods: {
        timeToString,
        handleShowOnPage(path) {
            const { selectedFrom, selectedTo, selectedCar, selectedEmployee } = this;
            const params = {
                from: format(selectedFrom, 'yyyy-MM-dd'),
                to: format(selectedTo, 'yyyy-MM-dd'),
                car: selectedCar?.value,
                driver: selectedEmployee?.value,
            };
            this.$router.push({ name: path, query: params });
        },
        handleRandomName() {
            const names = {
                0: `${fakerDE.person.firstName()} ${fakerDE.person.lastName()}`,
                1: `${fakerTR.person.firstName()} ${fakerTR.person.lastName()}`,
            };
            return names[Math.floor(Math.random() * 2)];
        },
        handleEmployeeRowClick({ index }) {},
        handleRowClick(driverNumber) {
            const workTime = this.workingTimes.find(wt => wt.driverNumber === driverNumber);
            if (!workTime || !workTime.pause.length) return;

            this.currentPauses = { ...workTime }.pause.map(p => {
                // Make sure we extract and preserve the uuid
                return {
                    id: p.uuid,
                    uuid: p.uuid,
                    row: [
                        {
                            name: 'Dauer (HH:min)',
                            value: getDuration(p.duration),
                            rawValue: p.duration,
                        },
                        {
                            name: 'Start',
                            value: format(parseISO(p.startAt), 'dd.MM.yy HH:mm'),
                            rawValue: p.startAt,
                        },
                        {
                            name: 'Ende',
                            value: format(parseISO(p.endAt), 'dd.MM.yy HH:mm'),
                            rawValue: p.endAt,
                        },
                        {
                            name: 'Akzeptiert',
                            props: {
                                keyName: 'accepted',
                                value: p.isActive,
                                uuid: p.uuid,
                            },
                            component: 'Checkbox',
                        },
                    ],
                    isAccepted: p.isAccepted,
                    isActive: p.isActive,
                };
            });

            this.pauseModalIsActive = true;

            // Initialize checked pauses based on backend state
            setTimeout(() => {
                this.checkedPauses = [];
                this.formattedPauses.forEach(pause => {
                    const isActive = pause.isActive || pause.isAccepted;
                    if (isActive) {
                        this.checkedPauses.push({ ...pause, isActive: true });
                    }
                });
            }, 50);
        },
        handlePauseSortChanged(sort) {
            this.pauseSortState = {
                column: sort.column,
                ascending: sort.ascending,
            };
        },
        handlePauseCheckChange(pause, isActive) {
            // Update the item's isActive property directly for reactivity
            pause.isActive = isActive;

            if (isActive) {
                // Check if this pause is already in the checkedPauses array
                const existingIndex = this.checkedPauses.findIndex(p => p.uuid === pause.uuid);
                if (existingIndex === -1) {
                    // Make a copy to avoid reference issues
                    const pauseCopy = { ...pause, isActive: true };
                    this.checkedPauses.push(pauseCopy);
                } else {
                    // Update the existing item's isActive property
                    this.checkedPauses[existingIndex].isActive = true;
                }
            } else {
                // Remove this pause from the checkedPauses array
                this.checkedPauses = this.checkedPauses.filter(p => p.uuid !== pause.uuid);
            }
        },
        async handleSavePauses() {
            // Format the data as expected by the backend with uuid and isActive keys
            const pauses = this.formattedPauses
                .map(item => {
                    const uuid = item.uuid;
                    if (!uuid) return null;

                    // Check if this pause is in the checkedPauses array
                    const isChecked = this.isItemChecked(item);

                    return {
                        uuid: uuid,
                        isActive: isChecked,
                    };
                })
                .filter(pause => pause !== null);

            const queryData = { pauses };

            this.isFetchingData = true;
            this.isSavingPauses = true;
            try {
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/work_hours/pauses/set_checked_state`;
                await axios.patch(url, queryData, {
                    withCredentials: true,
                });

                // Show success message
                this.$toasted.show('Pausen wurden erfolgreich aktualisiert', {
                    type: 'success',
                    duration: 3000,
                });

                await this.handleQuery();
                this.checkedPauses = [];
                this.handleModalClose();
            } catch (error) {
                console.error('Error saving pauses:', error);
                this.$toasted.show('Etwas ist schief gelaufen, bitte versuchen Sie es erneut', {
                    type: 'error',
                });
            } finally {
                this.isFetchingData = false;
                this.isSavingPauses = false;
            }
        },
        handleModalClose() {
            this.pauseModalIsActive = false;
            setTimeout(() => (this.currentPauses = []), 400);
        },
        handleDownload(type) {
            const year = this.selectedFrom.getYear() + 1900;
            const month = ('0' + (this.selectedFrom.getMonth() + 1)).slice(-2);
            const id = this.selectedEmployee.id;
            const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/work_hours/${year}/${month}/driver/${id}/${type}`;
            window.open(url, '_blank');
        },
        handleDateChange(dateRange) {
            this.selectedFrom = dateRange.from;
            this.selectedTo = dateRange.to;
        },
        handleDriverChange({ item }) {
            console.log('item', item);
            this.selectedEmployee = item;
        },
        async handleQuery() {
            this.isFetchingData = true;
            if (this.selectedEmployee) {
                await this.fetchEmployeeWorkTimeData();
            } else {
                this.employeeWorkTime = [];
                await this.fetchAllWorkTimeData();
            }
        },
        async fetchEmployeeWorkTimeData() {
            console.log('fetchEmployeeWorkTimeData', this.selectedEmployee);
            try {
                const queryData = {
                    rangeStartAt: this.selectedFrom,
                    rangeEndAt: this.selectedTo,
                };
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/work_hours/daily/driver/${this.selectedEmployee.id}`;
                const result = await axios.post(url, queryData, {
                    withCredentials: true,
                });
                this.employeeWorkTime = result.data;
            } catch (error) {
                this.$toasted.show('Etwas ist schief gelaufen, bitte versuchen Sie es erneut', {
                    type: 'error',
                });
            } finally {
                this.isFetchingData = false;
                this.$emit('onFinishLoading');
            }
        },
        async fetchAllWorkTimeData() {
            try {
                const queryData = {
                    rangeStartAt: format(this.selectedFrom, 'yy-MM-dd'),
                    rangeEndAt: format(this.selectedTo, 'yy-MM-dd'),
                };
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/work_hours`;
                const result = await axios.post(url, queryData, {
                    withCredentials: true,
                });
                this.workingTimes = result.data;
            } catch (error) {
                this.$toasted.show('Etwas ist schief gelaufen, bitte versuchen Sie es erneut', {
                    type: 'error',
                });
            } finally {
                this.isFetchingData = false;
                this.$emit('onFinishLoading');
            }
        },
        isItemChecked(item) {
            // First, check if the item is in the checkedPauses array by uuid
            return this.checkedPauses.some(p => p.uuid === item.uuid);
        },
        getModalTitle() {
            if (this.isSavingPauses) {
                return 'Pausen werden gespeichert...';
            }

            const checkedCount = this.checkedPauses.length;
            const totalCount = this.formattedPauses.length;

            if (checkedCount === 0) {
                return 'Markieren Sie die Pausen';
            }

            return `Pausen (${checkedCount}/${totalCount} ausgewählt)`;
        },
    },
    mounted() {
        const query = this.$route.query;
        const { from, to, driver } = query;
        if (from && isValid(parseISO(from))) {
            this.selectedFrom = parseISO(from); // Only set if the parsed date is valid
        }
        if (to && isValid(parseISO(to))) {
            this.selectedTo = parseISO(to); // Only set if the parsed date is valid
        }
        if (driver) {
            this.selectedEmployee = this.drivers.find(e => e.id == driver) || null;
        }
        setTimeout(() => {
            this.handleQuery();
        }, 100);
    },
};
</script>
<style lang="scss">
.WorkingTime {
    @extend %contentWrapper;
    @extend %page;

    .Spinner-Wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 80px 0;
    }
}
</style>
