<template>
    <div class="ToolbarActions--Group" v-if="isPlanBasic">
        <Button
            v-if="showShiftsButton && (selectedCar || selectedEmployee)"
            v-tooltip="'In Schichten Anzeigen'"
            variant="icon"
            @onClick="handleShowOnPage('shifts')"
        >
            <i class="ri-repeat-line"></i>
        </Button>
        <Button
            v-if="(selectedEmployee ||selectedCar) && showTripsButton"
            v-tooltip="'In Einzelfahrten Anzeigen'"
            variant="icon"
            @onClick="handleShowOnPage('trips')"
        >
            <i class="ri-steering-2-line"></i>
        </Button>
        <Button
            v-if="selectedEmployee && showWorkingTimeButton"
            v-tooltip="'In Arbeitszeiten Anzeigen'"
            variant="icon"
            @onClick="handleShowOnPage('working-time')"
        >
            <i class="ri-time-line"></i>
        </Button>
        <Button
            v-if="selectedCar && showOperationRideButton"
            v-tooltip="'In Betriebsnachweise Anzeigen'"
            variant="icon"
            @onClick="handleShowOnPage('operationride')"
        >
            <i class="ri-car-line"></i>
        </Button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from '@/components/widgets/Button.vue';

export default {
    name: 'PageNavigationToolbar',
    components: { Button },
    props: {
        selectedCar: {
            type: [Object, String, Number],
            default: null,
        },
        selectedEmployee: {
            type: [Object, String, Number],
            default: null,
        },
    },
    computed: {
        ...mapGetters(['isPlanBasic']),

        currentRoute() {
            return this.$route.path.split('/')[1];
        },

        showShiftsButton() {
            console.log('showShiftsButton', this.currentRoute);
            return this.currentRoute !== 'shifts';
        },

        showTripsButton() {
            return this.currentRoute !== 'trips';
        },

        showWorkingTimeButton() {
            return this.currentRoute !== 'working-time';
        },

        showOperationRideButton() {
            return this.currentRoute !== 'operationride';
        },
    },
    methods: {
        handleShowOnPage(page) {
            const query = {};

            if (this.selectedCar) {
                query.car =
                    typeof this.selectedCar === 'object'
                        ? this.selectedCar.id || this.selectedCar.uuid
                        : this.selectedCar;
            }

            if (this.selectedEmployee) {
                query.driver =
                    typeof this.selectedEmployee === 'object'
                        ? this.selectedEmployee.id || this.selectedEmployee.uuid
                        : this.selectedEmployee;
            }

            this.$router.push({ path: `/${page}`, query });
        },
    },
};
</script>

<style lang="scss" scoped>
.ToolbarActions--Group {
    display: flex;
    align-items: center;
    gap: 8px;
}
</style>
