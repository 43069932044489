<template>
    <BaseDashboardTable
        :columns="columns"
        :items="sortedWorkingTimes"
        :sort="sortState"
        :empty-message="'Keine Arbeitszeiten gefunden'"
        @sort-changed="handleSortChanged"
        @row-click="handleRowClick"
        @row-hover="handleRowHover"
        @row-leave="handleRowLeave"
    >
        <template #title>
            <h3>Arbeitszeiten</h3>
        </template>

        <!-- Custom pause column with icon -->
        <template #column-pauseIndicator="{ item }">
            <button
                v-if="hasPauses(item)"
                class="pause-button"
                @click.stop="handleRowClick({ item })"
                v-tooltip="`${item.pause.length} Pausen anzeigen`"
            >
                <i class="ri-time-line"></i>
            </button>
        </template>
    </BaseDashboardTable>
</template>

<script type="text/javascript">
import BaseDashboardTable from '@/components/BaseDashboardTable';
import { getDuration } from '@/lib/helper';

export default {
    name: 'WorkingTimesTable',
    components: {
        BaseDashboardTable,
    },
    props: {
        workingTimes: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            activeRow: -1,
            sortState: {
                column: 'driverName',
                ascending: true,
            },
            columns: [
                { key: 'driverName', label: 'Fahrer',  },
                { key: 'driverNumber', label: 'Fahrer Nummer', },
                { key: 'shiftTime', label: 'Schichtzeit', align: 'center' },
                { key: 'workTime', label: 'Arbeitszeit', align: 'center' },
                { key: 'pauseTime', label: 'Pause', align: 'center' },
                {
                    key: 'pauseIndicator',
                    label: 'Details',
                    align: 'center',
                    sortable: false,
                },
            ],
        };
    },
    computed: {
        formattedWorkingTimes() {
            if (!this.workingTimes || this.workingTimes.length === 0) {
                return [];
            }

            return this.workingTimes.map(workingTime => ({
                driverName: workingTime.driverName || '',
                driverNumber: workingTime.driverNumber || '',
                shiftTime: getDuration(workingTime.shiftTime),
                workTime: getDuration(workingTime.workTime),
                pauseTime: getDuration(workingTime.pauseTime),
                pauseIndicator: '',

                // Raw values for sorting
                _shiftTime: workingTime.shiftTime || 0,
                _workTime: workingTime.workTime || 0,
                _pauseTime: workingTime.pauseTime || 0,

                // Store full pause data
                pause: workingTime.pause || [],

                // Store original data for reference
                originalData: workingTime,
            }));
        },
        sortedWorkingTimes() {
            if (!this.formattedWorkingTimes.length) return [];

            const { column, ascending } = this.sortState;

            return [...this.formattedWorkingTimes].sort((a, b) => {
                // Determine which property to sort by
                let aValue, bValue;

                // Use raw numeric values for time fields
                if (column === 'shiftTime') {
                    aValue = a._shiftTime;
                    bValue = b._shiftTime;
                } else if (column === 'workTime') {
                    aValue = a._workTime;
                    bValue = b._workTime;
                } else if (column === 'pauseTime') {
                    aValue = a._pauseTime;
                    bValue = b._pauseTime;
                } else {
                    // For text fields, use the displayed value
                    aValue = a[column];
                    bValue = b[column];

                    // Case insensitive string comparison
                    if (typeof aValue === 'string' && typeof bValue === 'string') {
                        aValue = aValue.toLowerCase();
                        bValue = bValue.toLowerCase();
                    }
                }

                // Handle undefined or null values
                if (aValue === undefined || aValue === null) aValue = '';
                if (bValue === undefined || bValue === null) bValue = '';

                // Perform the comparison
                if (aValue < bValue) return ascending ? -1 : 1;
                if (aValue > bValue) return ascending ? 1 : -1;
                return 0;
            });
        },
    },
    methods: {
        handleSortChanged(sort) {
            this.sortState = {
                column: sort.column,
                ascending: sort.ascending,
            };
        },
        handleRowClick({ item }) {
            // Maintain the emit format from the original component
            this.$emit('onClick', item.driverNumber || item.originalData.driverNumber);
        },
        handleRowHover({ index }) {
            this.activeRow = index;
        },
        handleRowLeave() {
            this.activeRow = -1;
        },
        hasPauses(item) {
            return item.pause && item.pause.length > 0;
        },
        handlePauseClick(item) {
            // Emit event with pause details
            this.$emit('onPauseClick', {
                driverNumber: item.driverNumber,
                driverName: item.driverName,
                pauses: item.pause,
            });
        },
        handleRandomName() {
            const { fakerDE, fakerTR, fakerAR } = require('@faker-js/faker');

            const names = {
                0: `${fakerDE.person.firstName()} ${fakerDE.person.lastName()}`,
                1: `${fakerTR.person.firstName()} ${fakerTR.person.lastName()}`,
            };
            return names[Math.floor(Math.random() * 2)];
        },
    },
};
</script>

<style lang="scss" scoped>
.pause-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    color: var(--color-blue);
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 4px;
    transition: all 0.2s ease;

    i {
        font-size: 1rem;
    }

    &:hover {
        background-color: rgba(var(--color-blue-rgb), 0.1);
        color: var(--color-blue-dark);
    }

    &:active {
        transform: scale(0.97);
    }
}
</style>
