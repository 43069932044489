<template>
    <tr
        :class="{
            selected: isSelected,
            clickable: isClickable,
            checked: selectable && isChecked,
            expanded: expanded,
            'parent-row': hasChildren,
            'child-row': item._isChild,
            'gap-row': item._isGap,
        }"
        :style="{
            '--indent-level': item._level || 0,
        }"
        :id="item._id"
        @click="handleRowClick($event)"
        @mouseenter="handleRowHover"
        @mouseleave="handleRowLeave"
    >
        <td
            v-if="showCounter"
            class="counter-column text-center"
            :class="{ indented: item._isChild }"
        >
            {{ index }}
        </td>
        <td
            v-for="(column, colIndex) in columns"
            :key="colIndex"
            :class="[
                column.align ? `text-${column.align}` : '',
                { 'first-column': colIndex === 0 },
                { indented: colIndex === 0 && item._isChild },
            ]"
            v-tooltip="getColumnTooltip(column)"
        >
            <template v-if="colIndex === 0 && !item._isGap">
                <span
                    v-if="hasChildren || hasAnyExpandableRows"
                    :class="[
                        hasChildren ? 'expand-toggle' : 'expand-toggle-spacer',
                        { 'is-expanded': expanded },
                    ]"
                    @click.stop="hasChildren && toggleExpand()"
                >
                    <i
                        v-if="hasChildren"
                        :class="[expanded ? 'ri-arrow-down-s-line' : 'ri-arrow-right-s-line']"
                    ></i>
                </span>
            </template>

            <!-- Check for custom component or slot first -->
            <template v-if="column.component">
                <component
                    :is="resolveComponent(column.component)"
                    v-bind="getComponentProps(column)"
                    @input="handleComponentInput($event, column)"
                />
            </template>
            <template v-else>
                <!-- Check for slot -->
                <slot
                    :name="`column-${column.key}`"
                    :item="item"
                    :value="getItemValue(column)"
                    :column="column"
                >
                    <!-- Default content if no slot provided -->
                    <span>{{ truncateText(getItemValue(column), cellTruncateLength) }}</span>
                </slot>
            </template>
        </td>

        <td v-if="selectable" class="checkbox-column">
            <label class="checkbox-wrapper">
                <input
                    type="checkbox"
                    class="checkbox-input"
                    :checked="isChecked"
                    @change="toggleItemChecked"
                    @click.stop
                />
                <span class="checkmark" v-if="isChecked">
                    <i class="ri-check-line"></i>
                </span>
            </label>
        </td>
    </tr>
</template>

<script>
export default {
    name: 'BaseDashboardTableRow',
    props: {
        item: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        columns: {
            type: Array,
            required: true,
        },
        isClickable: {
            type: Boolean,
            default: true,
        },
        selectable: {
            type: Boolean,
            default: false,
        },
        hasAnyExpandableRows: {
            type: Boolean,
            default: false,
        },
        isChecked: {
            type: Boolean,
            default: false,
        },
        expanded: {
            type: Boolean,
            default: false,
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
        cellTruncateLength: {
            type: Number,
            default: 30,
        },
        globalComponents: {
            type: Object,
            default: () => ({}),
        },
        showCounter: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            throttleTimeout: null,
            throttledHover: null,
            throttledLeave: null,
        };
    },
    computed: {
        hasChildren() {
            return (
                (this.item.children && this.item.children.length > 0) ||
                this.item._hasChildren === true
            );
        },
    },
    methods: {
        handleRowClick(event) {
            // Special handling for gap rows
            if (this.item._isGap) {
                return; // Don't do anything for gap rows
            }

            // Don't handle click if the row isn't clickable
            if (!this.isClickable) return;

            // Find if user clicked directly on an interactive element
            const isInteractiveElement = event.target.closest('button, input, a, [role="button"]');

            // If user clicked on an interactive element (except expand toggle), don't handle row click
            if (isInteractiveElement && !isInteractiveElement.classList.contains('expand-toggle')) {
                return;
            }

            // If the row has children and user clicked on the expand toggle, only toggle expansion
            if (this.hasChildren && event.target.closest('.expand-toggle')) {
                this.toggleExpand();
                return;
            }

            // Handle general row click
            this.$emit('row-click', { item: this.item, index: this.index, event });

            // Also toggle expansion if the row has children
            if (this.hasChildren || this.item._hasChildren) {
                this.toggleExpand();
            }
        },

        // Throttle hover events to improve performance with large datasets
        throttle(fn, delay = 50) {
            return (...args) => {
                if (this.throttleTimeout) return;
                this.throttleTimeout = setTimeout(() => {
                    fn.apply(this, args);
                    this.throttleTimeout = null;
                }, delay);
            };
        },

        handleRowHover() {
            if (!this.throttledHover) {
                this.throttledHover = this.throttle(() => {
                    this.$emit('row-hover', { item: this.item, index: this.index });
                });
            }
            this.throttledHover();
        },

        handleRowLeave() {
            if (!this.throttledLeave) {
                this.throttledLeave = this.throttle(() => {
                    this.$emit('row-leave', { item: this.item, index: this.index });
                });
            }
            this.throttledLeave();
        },

        toggleExpand() {
            this.$emit('toggle-expand', { item: this.item, index: this.index });
        },

        toggleItemChecked() {
            if (!this.selectable) return;
            this.$emit('item-checked', this.item);
        },

        getItemValue(column) {
            let value;

            if (column.key.includes('.')) {
                const keys = column.key.split('.');
                value = this.item;
                for (const key of keys) {
                    if (value === null || value === undefined) return '';
                    value = value[key];
                }
            } else if (column.formatter && typeof column.formatter === 'function') {
                value = column.formatter(this.item[column.key], this.item);
            } else {
                value = this.item[column.key];
            }

            return value;
        },

        truncateText(text, maxLength = 30) {
            // Handle null, undefined, or non-string values
            if (text === null || text === undefined) return '';

            // Convert to string if it's not already
            const stringValue = String(text);

            // If text is already shorter than maxLength, return it as is
            if (stringValue.length <= maxLength) return stringValue;

            // Otherwise, truncate and add ellipsis
            return stringValue.substring(0, maxLength - 3) + '...';
        },

        getColumnTooltip(column) {
            // Get the raw value
            const value = this.getItemValue(column);

            // If the value is a string and would be truncated, show the full text in tooltip
            if (value !== null && value !== undefined) {
                const stringValue = String(value);
                if (stringValue.length > this.cellTruncateLength) {
                    return stringValue;
                }
            }

            // Otherwise use configured tooltip
            if (column.tooltip) {
                if (typeof column.tooltip === 'function') {
                    return column.tooltip(this.item);
                }
                return column.tooltip;
            }

            return null;
        },

        resolveComponent(component) {
            if (typeof component === 'string') {
                if (this.globalComponents && this.globalComponents[component]) {
                    return this.globalComponents[component];
                }

                if (this.$root.$options.components && this.$root.$options.components[component]) {
                    return component;
                }

                const parentComponents = this.$parent.$options.components || {};
                if (parentComponents[component]) {
                    return component;
                }

                console.warn(`Component "${component}" not found`);
                return 'div';
            }

            return component;
        },

        getComponentProps(column) {
            const defaultProps = {
                value: this.getItemValue(column),
                item: this.item,
                column: column,
            };

            if (column.props) {
                if (typeof column.props === 'function') {
                    return { ...defaultProps, ...column.props(this.item) };
                }
                return { ...defaultProps, ...column.props };
            }

            return defaultProps;
        },

        handleComponentInput(value, column) {
            this.$emit('update:item', {
                ...this.item,
                [column.key]: value,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
tr {
    &.is-visible {
        transform: translateY(0) !important;
    }

    &:not(.is-visible) {
        will-change: opacity, transform;
    }

    &.selected {
        background-color: rgba(var(--color-blue-rgb), 0.1);
    }

    &.clickable {
        cursor: pointer;

        &:hover {
            background-color: rgba(var(--color-blue-rgb), 0.05);
        }
    }

    &.checked {
        background-color: rgba(var(--color-blue-rgb), 0.1);
    }

    &.expanded {
        background-color: rgba(var(--color-blue-rgb), 0.05);
    }

    &.parent-row {
        font-weight: 500;
    }

    &.child-row {
        background-color: rgba(var(--color-blue-rgb), 0.02);
    }

    &.gap-row {
        cursor: default !important;

        &:hover {
            background-color: inherit !important;
        }
    }
}

td {
    padding: 10px 8px;
    font-size: 0.85rem;
    color: var(--color-text-black);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.first-column {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &.indented {
        padding-left: calc(8px + (var(--indent-level, 0) * 20px));
    }

    &.text-right {
        text-align: right;
    }

    &.text-center {
        text-align: center;
    }

    .expand-toggle,
    .expand-toggle-spacer {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.2s ease;

        i {
            font-size: 1.2rem;
            color: var(--color-gray);
            transition: transform 0.2s ease;
        }

        &:hover {
            background-color: rgba(var(--color-blue-rgb), 0.1);

            i {
                color: var(--color-blue);
            }
        }

        &.is-expanded i {
            transform: rotate(180deg);
        }
    }

    .expand-toggle-spacer {
        visibility: hidden;
    }
}

.checkbox-column {
    width: 40px;
    text-align: center;
    padding: 10px 4px;
}

.checkbox-wrapper {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
    cursor: pointer;

    .checkbox-input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ .checkmark {
            background-color: var(--color-blue);
            border-color: var(--color-blue);
        }
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: white;
        border: 2px solid rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        transition: all 0.2s ease;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            color: white;
            font-size: 0.9rem;
        }
    }

    &:hover .checkbox-input ~ .checkmark {
        border-color: var(--color-blue);
    }
}

.counter-column {
    position: sticky;
    left: 0;
    z-index: 2;

    &.indented {
        padding-left: calc(8px + (var(--indent-level, 0) * 20px));
    }
}
</style>
